import { SelectedLocation } from '~/lib/interfaces/restaurant'

export function isAutocompletePrediction(
  selectedLocation: SelectedLocation,
  property: string
): selectedLocation is google.maps.places.AutocompletePrediction {
  return property in selectedLocation
}

export function isGeocoderResult(
  selectedLocation: SelectedLocation,
  property: string
): selectedLocation is google.maps.GeocoderResult {
  return property in selectedLocation
}
